
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import store from "@/store/index";
import mixins from "@/mixins/index";
import RecommendedCourses from "@/components/ThemeLesson/RecommendedCourses.vue";
import RecommendedCoursesModal from "@/components/modal/CourseReserveContent.vue";
import TeacherEvaluation from "@/components/Article/TeacherEvaluation.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
interface remark {
  name: string;
  value: string | null;
}
export default defineComponent({
  name: "TeacherInfoCourseCommendSlide.vue",
  components: {
    RecommendedCourses,
    RecommendedCoursesModal,
    TeacherEvaluation,
  },
  mixins: [mixins],
  props: ["id", "info"],
  emits: ["followTeacher", "unfollowTeacher", "closeSlide"],
  data() {
    return {
      spinnerStatusCount: 0,
      recommendedCoursesList: [] as any,
      recommendedCoursesOriginalList: [] as any,
      recommendedCoursesModalInfo: {
        subjectImg: "" as string | null,
        teacherImg: "" as string | null,
        teacherId: 0 as number | null,
        time: "" as string | null,
        feature: "" as string | null,
        classType: "" as string | null,
        level: null as string | null,
        title: "" as string | null,
        content: "" as string | null,
        remark: [] as remark[],
        status: 0,
        link: "" as string | null,
        password: "" as string | null,
        teacherName: "" as string | null,
        type: "",
        subject: "",
        id: 0,
        timestamp: 0,
        endTimestamp: 0,
      },
      courseModalId: "ArticleSlide",
      recommendedCoursesModal: null as any,
    };
  },
  computed: {
    height() {
      return store.getters.height;
    },
  },
  watch: {
    height() {
      this.calculateHeaderHeight();
    },
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    this.recommendedCoursesGet({
      type: "B",
      limit: 6,
    });
    this.recommendedCoursesModal = new Modal(
      document.getElementById(`modal-${this.courseModalId}`)! as HTMLElement
    );
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    calculateHeaderHeight() {
      document.documentElement.style.setProperty(
        "--header-height",
        this.height + "px"
      );
    },
    modalOpenRecommendedCourses(data: any) {
      this.recommendedCoursesModalInfo = this.courseReserveDataConvert(data);
      this.recommendedCoursesModal.show();
    },
    recommendedCoursesGet(obj: { type: string; limit: number }) {
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      axios
        .get(
          `${serverUrl}class_search?type=${obj.type}&limit=${obj.limit}&showAll=1`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          this.recommendedCoursesOriginalList = res.data.data.course;
          for (let i = 0; i < res.data.data.course.length; i++) {
            this.recommendedCoursesList.push(
              this.courseReserveDataConvert(
                this.recommendedCoursesOriginalList[i]
              )
            );
          }
          this.spinnerStatusCount = this.spinnerStatusCount - 1;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    modalCloseRecommendedCourses(obj: { index: number }) {
      // this.recommendedCoursesList[obj.index].isReserved = true;
      this.recommendedCoursesModal.hide();
    },
    followTeacher() {
      this.$emit("followTeacher");
    },
    unfollowTeacher() {
      this.$emit("unfollowTeacher");
    },
    goToTeacherPage() {
      this.$emit("closeSlide");
    },
  },
});
