
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import { personalInfo } from "@/api/index";
import autosize from "autosize";
import mixins from "@/mixins/index";
import Pagination from "@/components/Pagination.vue";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "MessageBoard",
  components: {
    Pagination,
  },
  mixins: [mixins],
  props: ["info", "id", "articleId", "messagePending"],
  emits: ["updatePostMessage", "changePage"],
  data() {
    return {
      userPhoto: "",
      moreOptions: false,
      showMoreReplyClick: new Array<boolean>(),
      messageText: "",
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
    this.autosizeInit();
    this.personalInfoGet().then((res) => {
      this.userPhoto = res!.data.data.user.photo;
    });
    document.body.addEventListener("click", (event: MouseEvent) => {
      this.closeMoreOptions();
      this.messageHideMoreOptions();
    });
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    async personalInfoGet() {
      try {
        return await personalInfo();
      } catch (error) {
        console.log(error);
      }
    },
    moblieLeaveComment(obj: { postId: number; postIndex: number }, event: any) {
      if (event.inputType === "insertLineBreak") {
        event.preventDefault();
        // if (!event.shiftKey) {
        //   this.leaveComment(obj);
        // }
      }
    },
    // 回覆貼文
    leaveComment(obj: { postId: number; postIndex: number }) {
      const target = document.getElementById(
        `${this.id}-message-reply-textarea`
      )! as HTMLInputElement;
      const message = target.value;
      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning/${obj.postId}/comment`,
            {
              content: message,
              position: 1,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // res.data.data是將留言發送出去後，由 API 接收到的完整內容，包含用戶名字、該貼文 ID 等 ..
            // 必須將這裡完整的資料推入目前的結構，這樣才可以接續對該筆發出的留言進行按讚或回覆
            this.$emit("updatePostMessage", {
              postId: obj.postId,
              postIndex: obj.postIndex,
              message: res.data.data,
            });
          })
          .catch((error) => {
            console.error(error);
          });
this.messageText = "";
        target.value = "";
      }
      this.updateAutosize();
    },
    // 回覆貼文留言
    leaveComment2(obj: {
      replyId: number;
      postIndex: number;
      messageIndex: number;
      replyIndex: number;
    }) {
      const target = document.getElementById(
        `${this.id}-canvas-reply-comment-${obj.replyId}`
      )! as HTMLInputElement;
      const message = target.value;
      if (message.trim() != "") {
        axios
          .post(
            `${serverUrl}thematic_learning/${obj.replyId}/comment`,
            {
              content: message,
              position: 2,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            let buffer = {
              cnName: res.data.data.cnName,
              content: res.data.data.content,
              createdAt: res.data.data.createdAt,
              id: res.data.data.id,
              images: null,
              like: res.data.data.like,
              photo: res.data.data.photo,
              reply_to: null,
              role: res.data.data.role,
              user_id: res.data.data.user_id,
              nickname: res.data.data.nickname,
            };
            this.$emit("updatePostMessage", {
              replyId: obj.replyId,
              postIndex: obj.postIndex,
              messageIndex: obj.messageIndex,
              message: buffer,
            });
            const target = document.getElementById(
              `${this.id}-canvas-show-reply-comment-${obj.replyId}`
            )! as HTMLElement;
            target.style.display = "none";
            const replyBtn = document.getElementById(
              `${this.id}-reply-btn-${obj.replyId}`
            )! as HTMLElement;
            replyBtn.style.display = "flex";
          })
          .catch((error) => {
            console.error(error);
          });

        target.value = "";
      }
      this.updateAutosize();
    },
    updateAutosize() {
      let targets = document.querySelectorAll("textarea");
      for (let i = 0; i < targets.length; i++) {
        autosize.update(targets[i]);
        let target = targets[i];
        let buffer = target.value;
        target.value = target.value + "";
        target.value = buffer;
      }
    },
    // 處理發文中連結
    linkify(text: string) {
      // eslint-disable-next-line
      var urlRegex =
        /* eslint-disable no-useless-escape */
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="text-primary-default" target="_blank" href="' +
          url +
          '">' +
          url +
          "</a>"
        );
      });
    },
    autosizeInit() {
      autosize(document.querySelectorAll("textarea"));
    },
    // 按下留言更多選項內的編輯留言，顯示編輯輸入框，隱藏原本的顯示內容
    editMessageShow(obj: { id: number }) {
      let container = document.getElementById(
        `${this.id}-canvas-message-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `${this.id}-canvas-edit-message-comment-${obj.id}-container`
      );
      container?.classList.add("hide");
      editContainer?.classList.remove("hide");
      this.updateAutosize();
    },
    // 按下編輯留言輸入框的取消，取消編輯，回到顯示留言
    editMessageHide(obj: { id: number }) {
      this.autosizeInit();
      let container = document.getElementById(
        `${this.id}-canvas-message-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `${this.id}-canvas-edit-message-comment-${obj.id}-container`
      );
      container?.classList.remove("hide");
      editContainer?.classList.add("hide");
    },
    // 按下留言更多選項內的編輯留言，顯示編輯輸入框，隱藏原本的顯示內容
    editReplyShow(obj: { id: number }) {
      let container = document.getElementById(
        `${this.id}-canvas-reply-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `${this.id}-canvas-edit-reply-comment-${obj.id}-container`
      );
      container?.classList.add("hide");
      editContainer?.classList.remove("hide");
      this.updateAutosize();
    },
    // 按下編輯留言輸入框的取消，取消編輯，回到顯示留言
    editReplyHide(obj: { id: number }) {
      let container = document.getElementById(
        `${this.id}-canvas-reply-comment-${obj.id}-container`
      );
      let editContainer = document.getElementById(
        `${this.id}-canvas-edit-reply-comment-${obj.id}-container`
      );
      container?.classList.remove("hide");
      editContainer?.classList.add("hide");
    },
    showMessageReply(id: number, index: number) {
      let targetGroup = document.getElementsByClassName(
        `second-message-${id}-${index}`
      );
      for (let i = 0; i < targetGroup.length; i++) {
        targetGroup[i].classList.remove("hide");
      }

      let showMoreReplyBtn = document.getElementsByClassName(
        `show-more-reply-${id}-${index}`
      );

      for (let i = 0; i < showMoreReplyBtn.length; i++) {
        showMoreReplyBtn[i].classList.add("hide");
      }
    },
    messageShowMoreOptions(obj: { id: number; index: number }) {
      this.autosizeInit();
      let target = document.getElementById(
        `${this.modalId}-canvas-message-options-container-${obj.id}-${obj.index}`
      );
      target?.classList.remove("hide");
    },
    messageHideMoreOptions() {
      document.addEventListener("click", (event) => {
        const clickedElement = event.target as HTMLElement;
        const messageOptionsContainer = clickedElement.closest(
          ".message-options-container"
        );
        // if (!messageOptionsContainer) {
        const allMessageOptionsContainers = document.querySelectorAll(
          ".message-options-container"
        );
        allMessageOptionsContainers.forEach((container) => {
          container.classList.add("hide");
        });
        // }
      });
    },
    showMoreOptions() {
      this.moreOptions = true;
      // 更多選項的選單
    },
    closeMoreOptions() {
      this.moreOptions = false;
    },
    createTimeFormat(datetime: string) {
      return datetime.slice(0, 10);
    },
    // 顯示回覆 Input
    showReplyButton(targetId: number) {
      // console.log(targetId);
      const target = document.getElementById(
        `${this.id}-canvas-show-reply-comment-${targetId}`
      )! as HTMLElement;
      const replyBtn = document.getElementById(
        `${this.id}-reply-btn-${targetId}`
      )! as HTMLElement;
      const targetTextArea = document.getElementById(
        `${this.id}-canvas-reply-comment-${targetId}`
      )! as HTMLTextAreaElement;
      target.style.display = "flex";
      replyBtn.style.display = "none";
      targetTextArea.focus();
      this.autosizeInit();
      this.updateAutosize();
    },
    hrLineHide(length: number, index: number) {
      if (length === 1) {
        return "hide";
      } else if (length === 2 && index !== length - 1) {
        return "";
      } else if (length > 2 && index !== length - 1) {
        return "";
      }

      return "hide";
    },
    name(data: any) {
      if (data.role === "T" && data.foreignName !== null) {
        return data.foreignName;
      }
      if (data.role === "S" && data.nickname !== null) {
        return data.nickname;
      }
      return data.cnName;
    },
    changePage(page: number) {
      this.$emit("changePage", page);
    },
  },
});
