
import { defineComponent, PropType } from "vue";

interface recommendedCourses {
  index: number;
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  teacherName: string;
  teacherImg: null | string;
  type: number;
  feature: string;
  canReserve: boolean;
  classId: number;
  languagePhoto: string;
  isReserved: boolean;
  rating: number;
  category: string;
}

export default defineComponent({
  props: {
    recommendedCoursesInfo: {
      type: Object as PropType<recommendedCourses>,
      required: true,
    },
    recommendedCoursesOriginalList: {
      type: Object as PropType<recommendedCourses>,
      required: true,
    },
  },
  emits: ["modalOpenRecommendedCourses"],
  data() {
    return {};
  },
  methods: {
    modalOpenRecommendedCourses() {
      this.$emit(
        "modalOpenRecommendedCourses",
        this.recommendedCoursesOriginalList
      );
    },
  },
});
