
import { defineComponent } from "vue";
import store from "@/store/index";
export default defineComponent({
  name: "Template520",
  components: {},
  mixins: [],
  props: [],
  emits: [],
  data() {
    return {};
  },
  computed: {
    height() {
      return store.getters.height;
    },
  },
  watch: {
    height() {
      this.titleSticky();
    },
  },
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    this.titleSticky();
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {
    titleSticky() {
      let target = document.querySelector(".right-column") as HTMLElement;
      let screenWidth = window.innerWidth;
      let height = 0;
      if (screenWidth >= 1024) {
        height = this.height;
      } else {
        height = this.height;
      }
      target.style.top = `${height}px`;
      target.style.height = `calc(100vh - ${height}px)`;
    },
  },
});
