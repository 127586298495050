
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "TeacherEvaluation",
  components: {},
  mixins: [],
  props: ["info"],
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {
    //
  },
  created() {
    //
  },
  beforeMount() {
    //
  },
  mounted() {
    //
  },
  beforeUpdate() {
    //
  },
  updated() {
    //
  },
  beforeUnmount() {
    //
  },
  unmounted() {
    //
  },
  methods: {},
});
